<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">CRM </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Trattative effettuate</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <b-table :fields="fields" :items="negotiations" responsive show-empty> 
          <template #empty>
            <h5 class="text-center my-2 text-primary-light">Non sono state effettuate trattative.</h5>
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBreadcrumb,
  BBreadcrumbItem,
  BTable,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBreadcrumb,
    BBreadcrumbItem,
    BTable,
  },

  data: () => ({
    fields: [
      {
        key: "id",
        sortable: true,
      },
      {
        key: "nome",
        sortable: true,
      },
      {
        key: "cognome",
        sortable: true,
      },
      {
        key: "città_di_provenienza",
        sortable: true,
      },
      {
        key: "email",
        sortable: true,
      },
      {
        key: "telefono",
        sortable: true,
      },
      {
        key: "data_trattativa",
        sortable: true,
      },
      {
        key: "esito_trattativa",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    negotiations: [],

    //Helpers

    loading: false,
  }),

  created() {
    this.getEstimateList();
  },  

  methods: {
    async getEstimateList() {
      this.loading = true;

      try {
        const response = await Requests.getEstimatesList();
        this.estimates = response;
      } catch(err) {
        console.debug(err)
      }

      this.loading = false;
    },
  },
};
</script>
